import _Vue from 'vue';
import DaoOrders from '@/data/dao/dao-orders';
import DaoRelays from '@/data/dao/dao-relays';
import DaoProducts from '@/data/dao/dao-products';

export function daoInjection(Vue: typeof _Vue): void {
  Vue.prototype.$dao = {
    orders: DaoOrders,
    relays: DaoRelays,
    products: DaoProducts

  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $dao: {
      orders: typeof DaoOrders;
      relays: typeof DaoRelays;
      products: typeof DaoProducts;
    };
  }
}
