import wretch from "wretch";
import config from '@/config';

export default class ApiHelpers {
  static get<T>(path: string): Promise<T> {
    return wretch().url(`${config.api}${path}`).get().json();
  }

  static post<T>(path: string, data: any): Promise<T> {
    return wretch().url(`${config.api}${path}`).post(data).res();
  }

  static patch<T>(path: string, data: any): Promise<T> {
    return wretch().url(`${config.api}${path}`).patch(data).json();
  }

  static delete(path: string): Promise<any> {
    return wretch().url(`${config.api}${path}`).delete().json();
  }
}
