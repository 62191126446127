import Product from '@/data/models/product';
import TypeUtils from '@/helpers/type-utils';

export class OrderProducts {
   product: Product;
   quantity: number;

   static map(data: Partial<OrderProducts>): OrderProducts {
      if (!data) {
         return null;
      }

      const orderProducts: OrderProducts = new OrderProducts();

      orderProducts.quantity = TypeUtils.hasNumberOrDefault(data.quantity, 0);
      orderProducts.product = Product.map(data.product.id, TypeUtils.hasObjectOrDefault(data.product, null));

      return orderProducts;
   }


   get totalPrice(): number {
      return this.quantity * this.product.price;
   }
}
