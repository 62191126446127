import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import './plugins/bootstrap-vue';
import './plugins/firebase';
import './plugins/vuefire';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import config from './config/index';
import '@/data/di/index';

Vue.config.productionTip = config.tip;

new Vue({
   router,
   store,
   i18n,
   render: h => h(App)
}).$mount('#app');
