import Product from '@/data/models/product';
import { removeFromStorage, saveToStorage } from '@/helpers/storage';
import Relay from '@/data/models/relay';
import { addToCart, removeFromCart, removeAllFromCart } from '@/helpers/manage-cart';
import { ADD_TO_CART, REMOVE_FROM_CART, CONFIRM_CART, SELECT_RELAY, EMPTY_CART, ORDER_SUCCEEDED, REMOVE_ALL_FROM_CART, UPDATE_HEADER_HEIGHT } from './mutation-types';

const serializeCart = cart => {
   Object.keys(cart).forEach(key => {
      cart[key].product = Product.map(cart[key].product.id, cart[key].product).toJSON();
   });
};

export default {
   [ADD_TO_CART](state, product: Product) {
      addToCart(state.cart, product);
      serializeCart(state.cart);
      saveToStorage('cart', state.cart);
   },
   [REMOVE_FROM_CART](state, product: Product) {
      removeFromCart(state.cart, product);
      serializeCart(state.cart);
      saveToStorage('cart', state.cart);
   },
   [REMOVE_ALL_FROM_CART](state, product: Product) {
      removeAllFromCart(state.cart, product);
      serializeCart(state.cart);
      saveToStorage('cart', state.cart);
   },
   [CONFIRM_CART](state, selectedProducts: Product[]) {
      selectedProducts.forEach(product => {
         addToCart(state.cart, product);
      });

      serializeCart(state.cart);
      saveToStorage('cart', state.cart);
   },
   [SELECT_RELAY](state, relay: Relay) {
      state.selectedRelay = relay;
   },
   [EMPTY_CART](state) {
      state.cart = {};
   },
   [ORDER_SUCCEEDED](state) {
      state.selectedRelay = null;
      state.cart = {};
      removeFromStorage('cart');
   },
   [UPDATE_HEADER_HEIGHT](state, height: number) {
      state.headerHeight = height;
   }
};
