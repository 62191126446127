import firebase from 'firebase/app';
import 'firebase/firestore'

export default class TypeUtils {

  public static hasEnumOrDefault<T>(value: any, enumType: { [key: string]: any }, defaultValue = null): T | null {
    const found = Object.keys(enumType).find(v => enumType[v] === value);
    if (found) {
      return enumType[found];
    }
    return defaultValue;
  }

  public static hasIntegerOrDefault(value: any, defaultValue = null): number | null {
    if (value !== null && value !== undefined) {
      try {
        return Number.parseInt(value, 10);
        // eslint-disable-next-line no-empty
      } catch (ignored) {
      }
    }
    return defaultValue;
  }

  public static hasStringOrDefault(value: any, defaultValue = null): string | null {
    if (value) {
      return value.toString();
    }
    return defaultValue;
  }

  public static hasBooleanOrDefault(value: any, defaultValue = false): boolean | null {
    if (value === null || value === undefined) {
      return defaultValue;
    } else if (typeof value === "boolean") {
      return value;
    } else if (typeof value === "string" && value === "true") {
      return true;
    }
    return defaultValue;
  }

  public static hasDateOrDefault(value: firebase.firestore.Timestamp | string | Date, defaultValue = null): Date | null {
    if (value !== null && value !== undefined) {
      if (value instanceof firebase.firestore.Timestamp) {
        return value.toDate();
      } else if (typeof value === "string") {
        try {
          return new Date(value);
        } catch (e) {
          return defaultValue;
        }
      }
      return value;
    }
    return defaultValue;
  }

  public static hasObjectOrDefault<T>(value: T | any, defaultValue: null | T = null): T | null {
    if (!value || typeof value !== "object") {
      return defaultValue;
    }
    return value;
  }

  public static hasArrayOrDefault(value: any, defaultValue: null | any[] = null): any[] | null {
    if (value && Array.isArray(value)) {
      return value;
    }

    return defaultValue;
  }

  public static hasNumberOrDefault(value: any, defaultValue = 0.0): number | null {
    if (value !== null && value !== undefined) {
      return Number.parseFloat(value);
    }
    return defaultValue;
  }
}
