import TypeUtils from '@/helpers/type-utils';
import Place from '@/data/models/place';
import BaseModel from '@/data/models/base/base-model';
import { formatAddress } from '@/helpers/format-string';

export type time = { hour: number; minute: number };
export type daySchedule = { day: number; schedule: { open: time; close: time }[] };
export type weekSchedule = Array<daySchedule>;

export const fillWithEmptyPlanning = (): weekSchedule =>
   new Array(7).fill(0).map((value, day) => {
      return {
         day: day,
         schedule: []
      };
   }) as weekSchedule;

export enum RelayStatus {
   OPENED = 'opened',
   CLOSED = 'closed',
   DELETED = 'deleted'
}

export default class Relay extends BaseModel {
   name: string = '';
   place: Place = new Place();
   status: RelayStatus;
   planning: weekSchedule = fillWithEmptyPlanning();
   isEasyLaverie: boolean = false;

   static map(data: Partial<Relay>, id?: string): Relay | null {
      if (!data) {
         return null;
      }

      const relay: Relay = new Relay();
      relay.place = new Place();

      relay.id = TypeUtils.hasStringOrDefault(id, data.id);
      relay.name = TypeUtils.hasStringOrDefault(data.name);
      relay.place.address = TypeUtils.hasStringOrDefault(data.place?.address);
      relay.place.city = TypeUtils.hasStringOrDefault(data.place?.city);
      relay.place.zipCode = TypeUtils.hasNumberOrDefault(data.place?.zipCode, null);
      relay.place.lat = TypeUtils.hasNumberOrDefault(data.place?.lat, null);
      relay.place.long = TypeUtils.hasNumberOrDefault(data.place?.long, null);
      relay.planning = TypeUtils.hasArrayOrDefault(data.planning, fillWithEmptyPlanning()) as weekSchedule;
      relay.status = TypeUtils.hasEnumOrDefault(data.status, RelayStatus, RelayStatus.OPENED);
      relay.isEasyLaverie = TypeUtils.hasBooleanOrDefault(data.isEasyLaverie);
      
      return relay;
   }

   get formatAddress(): string {
      return formatAddress(this.place);
   }

   toJSON(): {} {
      return {
         name: this.name,
         place: this.place.toJSON(),
         planning: this.planning,
         status: this.status
      };
   }
}
