import DaoOrders from '@/data/dao/dao-orders';
import Order from '@/data/models/order';
import DaoProducts from '@/data/dao/dao-products';
import DaoRelays from '@/data/dao/dao-relays';
import OrderWithDetails from '@/data/models/order-with-details';
import {OrderProducts} from '@/data/models/order-products';
import firebase from 'firebase';
import DocumentData = firebase.firestore.DocumentData;
import DocumentReference = firebase.firestore.DocumentReference;

export default class OrdersService {
  private static orderDao = new DaoOrders();
  private static productDao = new DaoProducts();
  private static relayDao = new DaoRelays();

  public static getOrderWithAllInfo(orders: Order[]): Promise<OrderWithDetails[]> {
    const ops = orders.map((order) => {
      return Promise.all([
        this.relayDao.getRelayById(order.relayId),
        this.productDao.getProductsByIds(order.products?.map((product) => product.productId))
      ])
      .then(result => {
        const [relay, products] = result;
        const productsById = {};
        products.forEach(p => productsById[p.id] = p);
        return OrderWithDetails.map({
          order: order,
          relay: relay,
          products: order.products.map(orderProduct => OrderProducts.map({product: productsById[orderProduct.productId] || null, quantity: orderProduct.quantity}))
        });
      });
    });
    return Promise.all(ops);
  }

  static async getOrderById(id: string): Promise<OrderWithDetails | null> {
    return this.orderDao.getOrderById(id).then((order) => {
      return this.getOrderWithAllInfo([order]).then((orders) => orders[0]);
    });
  }

  static createOrder(order: Order): Promise<Order> {
    return this.orderDao.addOrder(order).then(order => Order.map(order));
  }

  static orderDoc(orderId: string): DocumentReference<DocumentData> {
    return this.orderDao.orderDoc(orderId);
  }
}
