import TypeUtils from '@/helpers/type-utils';

export default class Place {
  city: string;
  address: string;
  zipCode: number;
  lat: number;
  long: number;

  static map(data: Partial<Place>): Place {
    const place = new Place();
    place.city = TypeUtils.hasStringOrDefault(data.city);
    place.zipCode = TypeUtils.hasNumberOrDefault(data.zipCode);

    return place;
  }

  toJSON() {
    return {
      city: this.city,
      address: this.address,
      zipCode: this.zipCode,
    };
  }
}
