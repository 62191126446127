import AbstractFirebaseFirestoreDao from '@/data/dao/abstract-firebase-firestore-dao';
import FirestoreReferences from '@/constants/firestore-references';
import Product from '@/data/models/product';
import IProductDao from '@/data/dao/interfaces/i-product-dao';

export default class DaoProducts extends AbstractFirebaseFirestoreDao<Product> implements IProductDao {
   constructor() {
      super(FirestoreReferences.PRODUCTS);
   }

   getProductById(productId: string): Promise<Product> {
      return super.getById(productId).then(product => Product.map(product.id, product));
   }

   getProductsByIds(productsIds: string[]): Promise<Product[]> {
      return Promise.all(
         productsIds?.map(productId => {
            return this.getProductById(productId);
         }) ?? []
      );
   }

   getAllProducts(): Promise<Product[]> {
      return super.getAll();
   }
}
