import Relay from '@/data/models/relay';
import AbstractFirebaseFirestoreDao from '@/data/dao/abstract-firebase-firestore-dao';
import FirestoreReferences from '@/constants/firestore-references';
import {IDaoRelays} from '@/data/dao/interfaces/i-dao-relays';
import ApiHelpers from '@/data/dao/base/api-helpers';
import Place from '@/data/models/place';

export default class DaoRelays extends AbstractFirebaseFirestoreDao<Relay> implements IDaoRelays {

  constructor() {super(FirestoreReferences.RELAYS);}

  getAllRelays(): Promise<Relay[]> {
    return super.getAll().then((data) => data.map((relay) => Relay.map(relay)));
  }

  getRelayById(id: string): Promise<Relay> {
    return super.getById(id).then((data) => Relay.map(data, id));
  }

  askRelay(place: Place): Promise<void> {
    return ApiHelpers.post('/email/requestRelay', place.toJSON());
  }
}
