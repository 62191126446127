import Product from '@/data/models/product';
import SecureLS from 'secure-ls';

const ls = new SecureLS();

export const saveToStorage = (id: string, value: any): void => {
   ls.set(id, JSON.stringify(value));
};

export const getFromStorage = (id: string, defaultValue: any): any => {
   const fromStorage = ls.get(id);

   if (!fromStorage) {
      return defaultValue;
   }

   const parsed = JSON.parse(fromStorage);

   Object.keys(parsed).forEach(key => {
      if ((parsed[key], parsed[key].product, parsed[key].product.id)) {
         parsed[key].product = Product.map(parsed[key].product.id, parsed[key].product);
      }
   });

   return parsed ? parsed : defaultValue;
};

export const removeFromStorage = (id: string): void => {
   ls.remove(id);
};
