import HasId from "./has-id";
import ToJson from '@/data/models/base/to-json';

/**
 * BaseModel
 */
export default class BaseModel implements HasId , ToJson{
  public id: string;

  public setId(id: string): void {
    this.id = id;
  }

  public getId(): string {
    return this.id;
  }

  toJSON(): {} {
    return {};
  }
}
