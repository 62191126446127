






import { Component, Vue, Prop } from 'vue-property-decorator';

export enum ButtonType {
   default = 'default',
   secondary = 'secondary',
   transparent_primary = 'transparent_primary',
   transparent_white = 'transparent_white'
}

@Component({})
export default class Button extends Vue {
   @Prop()
   handleClick: () => null;

   @Prop({ required: true })
   title: string;

   @Prop({ default: false })
   outlined: boolean;

   @Prop({ default: 'primary' })
   color: string;

   @Prop({ default: false })
   disabled: boolean;

   @Prop({ default: false })
   isLoading: boolean;

   @Prop({ default: ButtonType.default })
   type: ButtonType;

   @Prop({ default: null })
   to: any;

   get backgroundStyle(): string {
      switch (this.type) {
         case ButtonType.default:
            return 'default-colors';
         case ButtonType.secondary:
            return 'secondary-colors';
         case ButtonType.transparent_primary:
            return 'transparent_primary_colors';
         case ButtonType.transparent_white:
            return 'transparent_white_colors';
         default:
            return '';
      }
   }

   handle() {
      if (this.handleClick) {
         this.handleClick();
      }
   }
}
