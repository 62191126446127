











































































import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/commons/Button.vue';
import { capitalizeFirstLetter, formatPrice } from '@/helpers/format-string';
import Product from '@/data/models/product';

@Component({
  components: { Button }
})
export default class Header extends Vue {
  scrollY: number = 0;
  menu: boolean = false;

  displayBadge(): boolean {
    return Object.values(this.$store.state.cart).length > 0;
  }

  showHome() {
    if (this.$route.name != 'home') {
      this.$router.push({ name: 'home' });
    }
  }

  getIcon(product: Product) {
    const _product = Product.map(product.id, product);
    return _product.getIcon();
  }

  formatPrice(price: number) {
    return formatPrice(price);
  }

  capitalizeFirstLetter(string: string): string {
    return capitalizeFirstLetter(string);
  }

  get cartQuantity(): number {
    return this.$store.getters.getCartQuantity;
  }

  get headerBackground() {
    const transparentHeaderRoutes = ['home', 'tracking', 'cart', '404'];

    if (this.$route.name !== undefined && !transparentHeaderRoutes.includes(this.$route.name)) {
      return 'header-background';
    }

    if (this.scrollY > 70.0) {
      return 'header-background';
    }

    return '';
  }

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.scrollY = window.scrollY;
  }
}
