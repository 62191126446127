import DaoProducts from '@/data/dao/dao-products';
import Product from '@/data/models/product';

export default class ProductsService {
   private static productDao = new DaoProducts();

   static getAllProducts(): Promise<Product[]> {
      return this.productDao.getAllProducts().then(data =>
         data
            .map(it => {
               return Product.map(it.id, it);
            })
            .filter(p => p.enabled)
            .sort((p1, p2) => p1.position - p2.position)
      );
   }
}
