











import { Component, Vue, Prop } from 'vue-property-decorator';
import CovidAlert from '@/components/home/CovidAlert.vue';

@Component({
   components: {
      CovidAlert
   }
})
export default class CovidTooltip extends Vue {
   dismissed: boolean = false;
   isCovidAlertOpen: boolean = false;
}
