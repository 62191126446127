import Vue from 'vue';
import { Cart, CartItem } from '@/data/models/types/order-types';
import Product from '@/data/models/product';

export const addToCart = (cart: Cart, product: Product, quantity?: number): void => {
   const alreadyExist: boolean = Object.prototype.hasOwnProperty.call(cart, product.id);

   if (alreadyExist) {
      cart[product.id].total = cart[product.id].total += quantity ?? 1;
   } else {
      Vue.set(cart, product.id, { product: product, total: quantity ?? 1 });
   }
};

export const removeFromCart = (cart: Cart, product: Product): void => {
    const alreadyExist: boolean = Object.prototype.hasOwnProperty.call(cart, product.id);
 
    if (alreadyExist) {
       if (cart[product.id].total === 1) {
          Vue.delete(cart, product.id);
       } else {
          cart[product.id].total = cart[product.id].total -= 1;
       }
    }
 };

 export const removeAllFromCart = (cart: Cart, product: Product): void => {
    const alreadyExist: boolean = Object.prototype.hasOwnProperty.call(cart, product.id);
 
    if (alreadyExist) {
        Vue.delete(cart, product.id);
    }
 };

export const getCartTotalPrice = (cart: Cart) => Object.values(cart).reduce((acc, curr) => ((acc as number) += (curr as CartItem).product.price * (curr as CartItem).total), 0);
export const getCartQuantity = (cart: Cart) => Object.values(cart).reduce((acc, curr) => ((acc as number) += (curr as CartItem).total), 0);
