import Place from '@/data/models/place';
import { OrderProducts } from '@/data/models/order-products';
import { daySchedule, time, weekSchedule } from '@/data/models/relay';

export const formatAddress = (place: Place | Partial<Place>): string => {
   return `${place?.address?.concat(', ') ?? ''} ${place?.zipCode ?? ''} ${place?.city ?? ''}`;
};

export const formatOrderProducts = (orderProduct: OrderProducts) => {
   return `${orderProduct.quantity}x ${orderProduct.product.name}`;
};

export const formatTimeH = (time: time) => `${('0' + time.hour).slice(-2)}h${('0' + time.minute).slice(-2)}`;

export const formatStringPlanning = (periods: daySchedule): string => {
   return periods.schedule
      .map((period, index) => {
         if (index != periods.schedule.length - 1) {
            return `${formatTimeH(period.open)} - ${formatTimeH(period.close)} / `;
         }
         return `${formatTimeH(period.open)} - ${formatTimeH(period.close)}`;
      })
      .join('');
};

export const formatPrice = (price: number): string => {
   if (!price || typeof price !== 'number') return '';

   return `${price.toFixed(2)} €`;
};

export const capitalizeFirstLetter = (string: string): string => {
   return string.charAt(0).toUpperCase() + string.slice(1);
};
