import TypeUtils from '@/helpers/type-utils';
import BaseModel from '@/data/models/base/base-model';
import {OrderProductsId} from '@/data/models/types/order-types';
import dayjs from 'dayjs';

export enum OrderStatus {
  WAIT_PAYMENT = 0, PAYMENT_FAILED = 1, PAID = 100, TAKEN_BY_DRIVER_GO = 200, TAKEN_BY_LAUNDRY = 300, READY_FOR_BACK = 400, TAKEN_BY_DRIVER_BACK = 500, READY_RELAY = 600, VALIDATED = 700
}

export default class Order extends BaseModel {
  // Ordered by client
  dateRequest: Date;

  // Ready for pickup by client at relay point
  dateReady: Date;

  // Order closed, client have picked up his products
  dateValidated: Date;

  clientName: string;
  clientLastName: string;
  clientEmail: string;
  clientPhone: string;
  status: OrderStatus;
  relayId: string;
  products: OrderProductsId[];
  bagsIds: string[];
  stripeSessionId: string;

  static map(data: Partial<Order>, id?: string): Order | null {
    if (!data) {
      return null;
    }

    const order: Order = new Order();

    order.id = TypeUtils.hasStringOrDefault(id, data.id);
    order.dateRequest = TypeUtils.hasDateOrDefault(data.dateRequest);
    order.dateReady = TypeUtils.hasDateOrDefault(data.dateReady);
    order.dateValidated = TypeUtils.hasDateOrDefault(data.dateValidated);
    order.clientName = TypeUtils.hasStringOrDefault(data.clientName);
    order.clientLastName = TypeUtils.hasStringOrDefault(data.clientLastName);
    order.clientEmail = TypeUtils.hasStringOrDefault(data.clientEmail);
    order.clientPhone = TypeUtils.hasStringOrDefault(data.clientPhone);
    order.status = TypeUtils.hasEnumOrDefault(data.status, OrderStatus, null);
    order.relayId = TypeUtils.hasStringOrDefault(data.relayId);
    order.products = TypeUtils.hasArrayOrDefault(data.products);
    order.bagsIds = TypeUtils.hasArrayOrDefault(data.bagsIds);
    order.stripeSessionId = TypeUtils.hasStringOrDefault(data.stripeSessionId);

    return order;
  }

  get clientFullName(): string {
    return `${this.clientName ?? ''} ${this.clientLastName ?? ''}`;
  }

  get stripeSessionNotNull() {
    return this.stripeSessionId !== null && this.stripeSessionId !== '';
  }

  toJSON() {
    return {
      dateRequest: dayjs().toDate(),
      clientName: this.clientName,
      clientLastName: this.clientLastName,
      clientEmail: this.clientEmail,
      clientPhone: this.clientPhone,
      status: OrderStatus.WAIT_PAYMENT,
      relayId: this.relayId,
      products: this.products,
    };
  }
}
