import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import publicRoutes from './public';
import Meta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes: [...publicRoutes],
   scrollBehavior() {
      return { x: 0, y: 0 };
   }
});

const handleRoute = (to: Route, from: Route, next: NavigationGuardNext) => {
   return next();
};

router.beforeEach((to, from, next) => {
   handleRoute(to, from, next);
});

export default router;
