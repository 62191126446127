<template>
   <div id="app">
      <Header id="header" />

      <transition>
         <router-view />
      </transition>

      <Footer />
      <CovidTooltip />

      <transition name="fade">
         <div v-if="showChatTooltip" class="chat-tooltip" @click="openCrisp">
            <span class=" emoji mr-3">👋</span>
            <div>
               <p class="font-weight-bold m-0">Besoin d'aide ?</p>
               <p class="m-0">Cliquez ici et commencez à discuter avec nous !</p>
            </div>

            <button class="btn position-absolute" @click.stop="closeTooltip"><img src="@/assets/images/chat_close_icon.svg" /></button>
         </div>
      </transition>
   </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/commons/Header';
import Footer from '@/components/commons/Footer';
import CovidTooltip from '@/components/commons/CovidTooltip';

@Component({
   components: {
      Footer,
      Header,
      CovidTooltip
   },
   metaInfo() {
      return {
         title: 'Clean & collect',
         titleTemplate: 'Easylaverie | %s',
         meta: [
            {
               vmid: 'description',
               name: 'description',
               content: 'Easy Laverie est une plateforme de laverie professionnelle basée à Nantes en Loire Atlantique, au service des particuliers et professionnels. Déposez votre linge en point relais et récupérez-le propre et emballé en 48 heures.'
            }
         ]
      };
   }
})
export default class App extends Vue {
   showChatTooltip = false;
   canShowChatTooltip = true;

   mounted() {
      this.initCrisp();
   }

   initCrisp() {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = String(process.env.VUE_APP_CRISP_WEBISTE_ID);
      (function() {
         const script = document.createElement('script');
         script.src = 'https://client.crisp.chat/l.js';
         script.async = true;
         document.getElementsByTagName('head')[0].appendChild(script);
      })();

      window.$crisp.push([
         'on',
         'session:loaded',
         () => {
            if (!window.$crisp.is('chat:opened')) {
               setTimeout(() => {
                  this.showChatTooltip = true;
               }, 3000);
            }
         }
      ]);

      window.$crisp.push([
         'on',
         'chat:opened',
         () => {
            this.showChatTooltip = false;
         }
      ]);
   }

   openCrisp() {
      window.$crisp.push(['do', 'chat:open']);
   }

   closeTooltip() {
      this.showChatTooltip = false;
      this.canShowChatTooltip = false;
   }
}
</script>

<style lang="scss">
@import './assets/main';

#app {
   position: relative;
   min-height: 100vh;
   width: 100vw;
   overflow: hidden;
}

.chat-tooltip {
   .emoji {
      font-size: 1.5rem;
   }

   display: flex;
   cursor: pointer;
   align-items: center;
   z-index: 1000000;
   padding: 10px 90px 10px 20px;
   min-height: 80px;
   border-radius: 40px;
   background: white;
   position: fixed;
   bottom: 10px;
   right: 14px;
   margin-left: 14px;
   box-shadow: $shadow;

   button {
      left: 0;
      top: 0;
      background: gray;
      border-radius: 100%;
      color: white;
      width: 33px;
      height: 33px;
      padding: 0;
      opacity: 0;

      img {
         width: 16px;
         height: 16px;
      }

      &:hover {
         color: white;
      }
   }

   * {
      transition: all 0.2s;
   }

   &:hover {
      * {
         opacity: 0.5;
      }

      button,
      img {
         opacity: 1;
      }
   }
}

.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   opacity: 0;
}

@media (max-width: 560px) {
   .chat-tooltip {
      padding: 10px 84px 10px 20px;
      min-height: 66px;
      bottom: 8px;
      right: 8px;
      margin-left: 8px;
   }
}
</style>
