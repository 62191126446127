










































import { Component, Vue } from 'vue-property-decorator';
import CovidAlert from '@/components/home/CovidAlert.vue';
import pages from '../../data/json/pages.json';

@Component({ components: { CovidAlert } })
export default class Footer extends Vue {
   isCovidAlertOpen: boolean = false;

   get menuPages() {
      return pages;
   }
}
