import BaseDao from '@/data/dao/interfaces/base-dao';
import firebase from 'firebase/app';
import "firebase/firestore"
import CollectionReference = firebase.firestore.CollectionReference;
import BaseModel from '@/data/models/base/base-model';

export default class AbstractFirebaseFirestoreDao<T> implements BaseDao<T> {

  constructor(protected reference: string = null) {
  }

  public getReferenceCollection(): CollectionReference {
    return firebase.firestore().collection(this.reference);
  }

  public getAll(): Promise<T[]> {
    return this.getReferenceCollection().get().then((data) => AbstractFirebaseFirestoreDao.toArray(data));
  }

  public getById(id: string): Promise<T> {
    if (!id) {
      return Promise.resolve(null);
    }
    return this.getReferenceCollection().doc(id).get().then((r) => AbstractFirebaseFirestoreDao.objectElseNull<T>(r));
  }

  public createWithId(id: string, element: Partial<T>): Promise<Partial<T>> {
    if (!id) {
      return Promise.reject(new Error("Provided ID is invalid for createWithId"));
    }
    if (element instanceof BaseModel) {
      element.setId(id);
    }

    return this.getReferenceCollection()
    .doc(id)
    .set((element instanceof BaseModel) ? element.toJSON() : Object.assign({}, element))
    .then(() => element);
  }

  public create(element: Partial<T>): Promise<Partial<T>> {
    return this.createWithId(this.getUID(), element);
  }

  public updateById(id: string, newValues: Partial<T>, merge: boolean = true): Promise<T> {
    return this.getReferenceCollection()
      .doc(id)
      .set(Object.assign({}, newValues), { merge: merge })
      .then(() => this.getById(id));
  }

  public deleteById(id: string): Promise<void> {
    return this.getReferenceCollection()
      .doc(id)
      .delete()
      .then(() => null);
  }

  public updateRoot(newValues: any): Promise<any> {
    return this.getReferenceCollection()
      .doc()
      .set(JSON.parse(JSON.stringify(newValues)), { merge: false });
  }

  public getUID(): string {
    return this.getReferenceCollection().doc().id;
  }

  protected static objectElseNull<T>(doc: any): T | null {
    if (doc === null || !doc.exists) {
      return null;
    }

    const val = doc.data();
    val.id = doc.id;
    if (!val) {
      return null;
    }

    return val;
  }

  static toArray(data: any): any[] {
    if (!data || !data.docs) {
      return [];
    }

    const docs = [];
    data.docs.forEach((doc) => {
      docs.push({...doc.data(), id: doc.id});
    });
    return docs;
  }
}
