import TypeUtils from '@/helpers/type-utils';
import BaseModel from '@/data/models/base/base-model';

export default class Product extends BaseModel {
   name: string = '';
   description: string = '';
   picture: string = '';
   price: number = 0;
   type: string = '';
   feather: boolean = false;
   enabled: boolean = false;
   position: number = 0;

   static map(id: string, data: Partial<Product>): Product | null {
      if (!data) {
         return null;
      }

      const product: Product = new Product();

      product.id = TypeUtils.hasStringOrDefault(id, data.id);
      product.name = TypeUtils.hasStringOrDefault(data.name, '');
      product.description = TypeUtils.hasStringOrDefault(data.description, '');
      product.picture = TypeUtils.hasStringOrDefault(data.picture, '');
      product.price = TypeUtils.hasNumberOrDefault(data.price);
      product.type = TypeUtils.hasStringOrDefault(data.type);
      product.feather = TypeUtils.hasBooleanOrDefault(data.feather);
      product.enabled = TypeUtils.hasBooleanOrDefault(data.enabled);
      product.position = TypeUtils.hasNumberOrDefault(data.position);

      return product;
   }

   getIcon() {
      return `img/products/${this.type}.png`;
   }

   get alt() {
     return this.type.replaceAll("-", " ")
   }

   toJSON() {
      return {
         id: this.id,
         name: this.name,
         description: this.description,
         picture: this.picture,
         price: this.price,
         type: this.type,
         feather: this.feather,
         enabled: this.enabled,
         position: this.position
      };
   }
}

export class NewProduct extends Product {
   id = 'newProduct';
}
