export default {
  firebase: {
    apiKey: String(process.env.VUE_APP_FIREBASE_API_KEY),
    authDomain: String(process.env.VUE_APP_FIREBASE_AUTH_DOMAIN),
    databaseURL: String(process.env.VUE_APP_FIREBASE_DATABASE_URL),
    projectId: String(process.env.VUE_APP_FIREBASE_PROJECT_ID),
    storageBucket: String(process.env.VUE_APP_FIREBASE_STORAGE_BUCKET),
    messagingSenderId: String(process.env.VUE_APP_FIREBASE_MESSAGINGSENDER_ID),
    appId: String(process.env.VUE_APP_FIREBASE_APP_ID),
    measurementId: String(process.env.VUE_APP_FIREBASE_MEASUREMENT_ID),
  },
  googleApiKey: String(process.env.VUE_APP_GOOGLE_API_KEY),
  stripeApiKey: String(process.env.VUE_APP_STRIPE_API_KEY),
  tip: process.env.VUE_APP_TIP === 'true' || false,
  api: String(process.env.VUE_APP_API_ENDPOINT)
};
