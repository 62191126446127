import Order from '@/data/models/order';
import 'firebase/firestore';
import AbstractFirebaseFirestoreDao from '@/data/dao/abstract-firebase-firestore-dao';
import FirestoreReferences from '@/constants/firestore-references';
import IDaoOrder from '@/data/dao/interfaces/i-dao-order';
import firebase from 'firebase/app';
import DocumentData = firebase.firestore.DocumentData;
import DocumentReference = firebase.firestore.DocumentReference;

export default class DaoOrders extends AbstractFirebaseFirestoreDao<Order> implements IDaoOrder {

  constructor() {
    super(FirestoreReferences.ORDERS);
  }

  addOrder(order: Order): Promise<Partial<Order>> {
    return super.create(order);
  }

  getOrderById(id: string): Promise<Order> {
    return super.getById(id).then((data) => Order.map(data, id));
  }

  orderDoc(orderId: string): DocumentReference<DocumentData> {
    return firebase.firestore().collection(FirestoreReferences.ORDERS).doc(orderId);
  }
}
