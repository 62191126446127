import { RouteConfig } from 'vue-router';
import pages from '../data/json/pages.json';

const publicRoutes: Array<RouteConfig> = [
   {
      path: '/',
      name: 'home',
      meta: {
         secure: false
      },
      component: () => import('@/views/Home.vue')
   },
   {
      path: '/panier',
      name: 'cart',
      meta: {
         secure: false
      },
      component: () => import('@/views/Cart.vue')
   },
   {
      path: '/suivi',
      name: 'tracking',
      meta: {
         secure: false
      },
      component: () => import('@/views/Tracking.vue')
   },
   {
      path: '/cgv',
      name: 'cgv',
      meta: {
         secure: false
      },
      component: () => import('@/views/CGV.vue')
   },
   {
      path: '/legal',
      name: 'legal',
      meta: {
         secure: false
      },
      component: () => import('@/views/Legal.vue')
   },
   { path: '/404', component: () => import('@/views/NotFound.vue') },
   { path: '*', redirect: '/404' }
];

pages.forEach(page => {
   publicRoutes.push({
      path: page.path,
      name: page.path,
      component: () => import('@/views/Template.vue'),
      meta: page
   });
});

export default publicRoutes;
