









import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/commons/Button.vue';

@Component({
   components: { Button }
})
export default class CovidAlert extends Vue {
   @Prop({ required: true })
   opened: boolean;

   close() {
      this.$emit('closeDetail');
   }
}
