import _Vue from 'vue';
import OrdersService from '@/services/orders-service';
import RelayService from '@/services/relay-service';
import ProductsService from '@/services/products-service';

export function serviceInjection(Vue: typeof _Vue): void {
  Vue.prototype.$service = {
    orders: OrdersService,
    relays: RelayService,
    products: ProductsService
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $service: {
      orders: typeof OrdersService;
      relays: typeof RelayService;
      products: typeof ProductsService;
    };
  }
}
