import Order from '@/data/models/order';
import Relay from '@/data/models/relay';
import {OrderProducts} from '@/data/models/order-products';
import TypeUtils from '@/helpers/type-utils';

export default class OrderWithDetails {
  order: Order;
  relay: Relay;
  products: OrderProducts[];

  static map(data: Partial<OrderWithDetails>): OrderWithDetails | null {
    if(!data) {
      return null;
    }

    const orderWithDetails: OrderWithDetails = new OrderWithDetails();

    orderWithDetails.order = TypeUtils.hasObjectOrDefault(data.order);
    orderWithDetails.relay = TypeUtils.hasObjectOrDefault(data.relay);
    orderWithDetails.products = TypeUtils.hasObjectOrDefault(data.products);

    return orderWithDetails;
  }

  get totalPrice(): number {
    return this.products.reduce((acc, curr) => (acc  + curr.totalPrice), 0);
  }
}
