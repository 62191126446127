import Vue from 'vue';
import Vuex from 'vuex';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import { Cart } from '@/data/models/types/order-types';
import { getFromStorage } from '@/helpers/storage';
import Relay from '@/data/models/relay';

Vue.use(Vuex);

export interface AppState {
   cart: Cart;
   selectedRelay: Relay;
   headerHeight: number;
}

export default new Vuex.Store<AppState>({
   state: {
      cart: getFromStorage('cart', {}),
      selectedRelay: null,
      headerHeight: 0
   },
   getters: getters,
   mutations: mutations
});
