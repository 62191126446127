import DaoRelay from '@/data/dao/dao-relays';
import Relay, {RelayStatus} from '@/data/models/relay';
import Place from '@/data/models/place';

export default class RelayService {

  private static daoRelay = new DaoRelay();

  static getAllRelays(): Promise<Relay[]> {
    return this.daoRelay.getAllRelays().then((relays) => relays.filter(relay => relay.status !== RelayStatus.DELETED));
  }

  static getRelayById(id: string): Promise<Relay> {
    return this.daoRelay.getRelayById(id);
  }

  static askRelay(place: Place): Promise<void> {
    return this.daoRelay.askRelay(place);
  }

}
